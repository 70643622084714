import { NavigateFunction } from "react-router-dom";
import { AppRoute } from "../routes";
const { request, gql } = require("graphql-request");

class _AuthService {
  private sKeyEmail = "email";
  private sKeyToken = "token";
  private sKeyLastLogin = "lastLogin";
  private sKeyFirstLoginShown = "firstLoginShown";
  private skeydeviceId = "deviceId";

  login(
    email: string,
    accessToken: string,
    lastLogin: Date | null,
    deviceId: string
  ) {
    localStorage.setItem(this.sKeyEmail, email);
    localStorage.setItem(this.sKeyToken, accessToken);
    localStorage.setItem(
      this.sKeyLastLogin,
      lastLogin ? lastLogin.toString() : ""
    );
    localStorage.setItem(this.skeydeviceId, deviceId);
    sessionStorage.setItem(this.sKeyFirstLoginShown, "false");
  }

  async logout(navigate: NavigateFunction) {
    localStorage.clear();
    sessionStorage.clear();
    navigate(AppRoute.AccountLogin);
    // window.location.reload();
  }

  authenticate(navigate: NavigateFunction) {
    if (!this.getToken()) {
      this.logout(navigate);
      return false;
    }

    // const email = localStorage.getItem(this.sKeyEmail);

    // if (!email) {
    //   this.logout(navigate);
    //   return false;
    // }

    // const token = localStorage.getItem(this.sKeyToken);
    // const deviceId = localStorage.getItem(this.skeydeviceId);
    // const query = gql`
    //   query verifyDevice($email: String!, $deviceId: String!) {
    //     verifyDevice(email: $email, deviceId: $deviceId)
    //   }
    // `;
    // console.log("deviceId", deviceId);
    // const authenticated = await request(
    //   process.env.REACT_APP_GRAPHQL_ENDPOINT,
    //   query,
    //   { email, deviceId },
    //   {
    //     Authorization: `Bearer ${token}`,
    //   }
    // );

    // console.log("authenticated", authenticated);

    // if (!authenticated.verifyDevice) {
    //   navigate(AppRoute.LoginVefitication);
    //   return false;
    // }

    return true;
  }

  getEmail() {
    return localStorage.getItem(this.sKeyEmail) || undefined;
  }

  getToken() {
    return localStorage.getItem(this.sKeyToken.trim()) || undefined;
  }

  isFirstLogin(): boolean {
    const lastLogin = localStorage.getItem(this.sKeyLastLogin);
    const firstLoginShown = sessionStorage.getItem(this.sKeyFirstLoginShown);
    return !lastLogin && firstLoginShown === "false";
  }

  setFirstLoginShown() {
    sessionStorage.setItem(this.sKeyFirstLoginShown, "true");
  }

  setDeviceId(deviceId: string) {
    localStorage.setItem(this.skeydeviceId, deviceId);
  }
}

export const AuthService = new _AuthService();
