import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@apollo/client";
import { FINALIZE_PAYMENT } from '../../graphql/mutations/deposit';
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { ReactComponent as SvgLogo } from '../../assets/img/common/logo.svg';
import { GET_PAYMENT_INTENT_STATUS } from '../../graphql/queries/get-payment-intent';

export function StripeRedirect() {
  const [paymentStatus, setPaymentStatus] = useState<'processing' | 'succeeded' | 'failed' | 'requires_action' | 'requires_payment_method' | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const hasFinalizedRef = useRef(false); // Using useRef to track if API call has been made
  const navigate = useNavigate();
  const location = useLocation();

  const paymentIntentId = new URLSearchParams(location.search).get('payment_intent');

  const { data, loading, error } = useQuery(GET_PAYMENT_INTENT_STATUS, {
    variables: { paymentIntentId },
  });
  // const [finalizePayment, { loading, data, error }] = useMutation(FINALIZE_PAYMENT , { errorPolicy: 'all' });

  // Ensure the mutation is fired only once using `hasFinalizedRef`
  // useEffect(() => {
  //   const finalize = async () => {
  //     if (paymentIntentId && !hasFinalizedRef.current) {  // Check if it's already finalized
  //       console.log('calling API once');
  //       // await finalizePayment({ variables: { paymentIntentId } });
  //       hasFinalizedRef.current = true; // Mark as finalized using ref
  //     }
  //   }

  //   finalize();
  // }, [paymentIntentId]);

  console.log('data', data);
  console.log('error', error);

  useEffect(() => {
    if (error) {
      setErrorMessage('An error occurred while checking payment status.');
      console.error(error);
      return;
    }

    if (data) {
      const status = data.getPaymentIntentStatus.status;

      if (status === 'succeeded') {
        navigate('/vault');
      } else if (status === 'requires_action') {
        setErrorMessage('Additional action is required to complete the payment.');
      } else if (status === 'failed') {
        navigate('/vault');
        setErrorMessage('The payment failed. Please try again.');
      } else if (status === 'requires_payment_method') {
        navigate('/vault');
        setErrorMessage('The payment requires a different payment method.');
      }
    }
  }, [data, navigate, error]);


  if (loading) {
    return (
      <section className='m-auto bg-gray-100'>
        <div className='flex flex-col items-center w-full max-w-xl px-8 py-12 text-center bg-white rounded-lg shadow-lg'>
          <GlobalErrorHandler />
          <SvgLogo className='mx-auto mt-0 mb-20 text-center max-w-52' />
          <p className='mb-4 text-2xl font-semibold text-gray-700'>Processing your payment...</p>
          <p className='mb-8 text-lg text-gray-500'>Please wait, this might take a moment.</p>
          <div className='flex justify-center'>
            <svg className="w-10 h-10 text-blue-500 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
            </svg>
          </div>
        </div>
      </section>
    );
  }


  // useEffect(() => {
  //   if (data) {
  //     const status = data.finalizePayment;
  //     setPaymentStatus(status);
  //     console.log('status', status);

  //     if (status === 'succeeded') {
  //       navigate('/vault'); // Redirect to vault or other success page
  //     } else if (status === 'requires_action') {
  //       setErrorMessage('Additional action is required to complete the payment.');
  //       //add delay to show the error message
  //       setTimeout(() => {
  //         navigate('/vault'); 
  //       }, 3000);
  //     } else if (status === 'failed') {
  //       setErrorMessage('The payment failed. Please try again.');
  //       setTimeout(() => {
  //         navigate('/vault'); 
  //       }, 3000);
  //     }else if (status === 'requires_payment_method') {
  //       setErrorMessage('The payment failed. Please try again.');
  //       setTimeout(() => {
  //         navigate('/vault'); 
  //       }, 3000);
  //     } 
  //   }

  //   if (error) {
  //     setErrorMessage(error.message || 'An error occurred while finalizing the payment.');
  //   }
  // }, [data, error, navigate]);

  // if (loading) {
  //   return (
  //     <section className='items-center content'>
  //       <div className='min-w-full text-xl font-bold text-center card'>
  //         <GlobalErrorHandler />
  //         <SvgLogo className='mx-auto mt-0 mb-20 text-center max-w-52 lg:mt-16' />
  //         <p className='text-xl font-bold text-center'>Processing your payment, please wait...</p>

  //       </div>
  //     </section>
  //   );
  // }

  return (
    <div>
      <GlobalErrorHandler />
      <section className='items-center content'>
        <div className='flex flex-col items-center justify-center min-w-full text-xl font-bold align-middle card'>
          <SvgLogo className='mx-auto mt-0 mb-20 text-center max-w-52 lg:mt-16' />
          {/* {error && <p className='pb-4 text-xl font-bold text-center'>{errorMessage}</p>} */}
          {paymentStatus === 'succeeded' && <p className='text-xl font-bold text-center'>Payment successful! Redirecting to your vault...</p>}
          {paymentStatus === 'requires_action' && <p className='text-xl font-bold text-center'>{errorMessage}</p>}
          {paymentStatus === 'failed' && <p className='text-xl font-bold text-center'>{errorMessage}</p>}
          {paymentStatus === 'requires_payment_method' && <p className='text-xl font-bold text-center'>{errorMessage}</p>}
          {/* {error && <div className="p-4 btn" onClick={()=> navigate('/vault')} >Go back</div>} */}
        </div>
      </section>
    </div>
  );
}
