import { useLazyQuery } from "@apollo/client";
import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { Sidebar } from "../../components/sidebar/sidebar";
import { QUERY_GET_PROPERTY_LIST } from "../../graphql/queries/get-properties";
import { AppRoute } from "../../routes";
import { AuthService } from "../../services/auth.service";
import {
  toHumanReadableCurrencyDisplay,
  toCurrencyDisplay,
} from "../../utils/currency.util";
import { toPercentageDisplay } from "../../utils/string.util";
import { getPropertyImageUrl } from "../../utils/image.utils";
import CardSkeleton from "../../components/skeleton/cardskeleton";
import { QUERY_GET_PROPERTY_TYPES } from "../../graphql/queries/property-types";
import { Property, PropertyPledgeStatus, PropertyStatus, PropertyType } from "../../__generated__/graphql";
import WelcomePopup from "../../components/modal/welcome-popup";
import { QUERY_GET_BOOKMARKED_PROPERTIES } from "../../graphql/queries/property-bookmark";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";

import { useSettings } from "../../context/settings";
import NotificationTopBar from "../../components/notification/notification-bar";
import { NotificationContext } from "../../context/Notification";
import BedSvg from "../../assets/svgs/bed.svg";
import BathSvg from "../../assets/svgs/bath.svg";
import ArrowUPSvg from "../../assets/svgs/arrow-up.svg";

export function PropertiesPage() {
  const navigate = useNavigate();

  const [getProperties, { loading, error, data }] = useLazyQuery(
    QUERY_GET_PROPERTY_LIST,
    { errorPolicy: "all" }
  );
  const [
    getTypes,
    { data: typesData, loading: loadingtypes, error: errortypes },
  ] = useLazyQuery(QUERY_GET_PROPERTY_TYPES, { errorPolicy: "all" });
  const [properties, setProperties] = useState<any[]>();
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [
    getBookmarkedProperties,
    {
      data: bookmarkedPropertiesData,
      loading: loadingBookmarkedProperties,
      error: errorBookmarkedProperties,
    },
  ] = useLazyQuery(QUERY_GET_BOOKMARKED_PROPERTIES, { errorPolicy: "all" });
  const [selectedTabItem, setSelectedTabItem] = useState("Available");
  const [selectedType, setSelectedType] = useState<PropertyType | null>(null);
  const { settings } = useSettings();
  const {
    turnNotificationsOn,
    hideNotificationBar,
    loading: notificationLoading,
  } = useContext(NotificationContext);

  useAuthAndErrorHandling(error);
  useAuthAndErrorHandling(errortypes);
  useAuthAndErrorHandling(errorBookmarkedProperties);

  const fundedPercentage = (property: any) => {
    const confirmedPledges = property?.pledges?.filter(
      (pledge: any) => pledge.status === PropertyPledgeStatus.Confirmed
    );
    const totalConfirmedAmount = confirmedPledges?.reduce(
      (sum: any, pledge: any) => sum + pledge.amount,
      0
    );
    return ((totalConfirmedAmount / property.price) * 100).toFixed(2);
  };

  useEffect(() => {
    if (data || bookmarkedPropertiesData) {
      let allProperties: any[] = [];

      if (selectedTabItem === "Bookmarked" && bookmarkedPropertiesData) {
        allProperties = bookmarkedPropertiesData.bookmarkedProperties;
      } else if (data) {
        allProperties = data.properties;
      }
      if (selectedType === null) {
        setProperties(allProperties);
      } else {
        const filteredProperties = allProperties.filter(
          (property: Property) => property?.type?.id === selectedType.id
        );
        setProperties(filteredProperties);
      }
    }
  }, [data, bookmarkedPropertiesData, selectedType, selectedTabItem]);

  useEffect(() => {

    if (!AuthService.authenticate(navigate)) {
      return;
    }

    if (AuthService.isFirstLogin()) {
      setShowWelcomePopup(!showWelcomePopup);
      AuthService.setFirstLoginShown();
    }

    console.log("notifications",)
    // getProperties({
    //   variables: {
    //     take: 50,
    //     skip: 0,
    //     where: { filters: getFiltersForTabItem(selectedTabItem) },
    //   },
    // });
    getTypes();
  }, []);

  useEffect(() => {
    if (error) {
      console.error("GraphQL Error:", error);
    }
  }, [data, error]);


  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
    if (selectedTabItem === "Bookmarked") {
      getBookmarkedProperties();
    } else {
      getProperties({
        variables: {
          take: 50,
          skip: 0,
          where: { filters: getFiltersForTabItem(selectedTabItem) },
        },
      });
    }
  }, [selectedTabItem]);

  function handleTabItemClick(tabItem: string) {
    setSelectedTabItem(tabItem);
  }

  function getClassNameForTabItem(tabItem: string) {
    return selectedTabItem === tabItem ? "tab-item selected" : "tab-item";
  }

  function handleTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedTypeId = event.target.value;
    if (selectedTypeId === "all") {
      setSelectedType(null);
    } else {
      const selectedType =
        typesData?.propertyTypes.find(
          (type: PropertyType) => type.id === selectedTypeId
        ) || null;
      setSelectedType(selectedType);
    }
  }

  function getFiltersForTabItem(tabItem: string) {
    switch (tabItem) {
      case "Available":
        return [
          { field: "is_funded", operator: "=", value: "false" },
          { field: "status", operator: "IN", values: ["active", "coming_soon"] }, // Use values for arrays
        ];
      case "Funded":
        return [
          { field: "is_funded", operator: "=", value: "true" },
          { field: "status", operator: "=", value: "active" },
        ];
      case "Exited":
        return [{ field: "code", operator: "=", value: "XXXAAA" }];
      default:
        return [];
    }
  }

  function calculateEstimatedROI(data: any) {
    const investmentCost =
      (data.price || data.agreementPrice) +
      data.transactionFee +
      data.platformFee;

    const annualNetIncome =
      (data.rents[0]?.rent * 12 || 0) -
      (data.financials[0]?.annualServiceCharges || 0) -
      (data.financials[0]?.annualManagementAndMaintenanceCharges || 0);

    if (investmentCost === 0) {
      return 0; // To prevent division by zero
    }

    const estimatedROI = (annualNetIncome / investmentCost) * 100;
    return estimatedROI.toFixed(0); // Return ROI rounded to 2 decimal places
  }

  function calculateEstimated3yROI(data: any) {
    const investmentCost =
      (data.price || data.agreementPrice) +
      data.transactionFee +
      data.platformFee;

    const annualNetIncome =
      (data.rents[0]?.rent * 12 || 0) -
      (data.financials[0]?.annualServiceCharges || 0) -
      (data.financials[0]?.annualManagementAndMaintenanceCharges || 0);

    if (investmentCost === 0) {
      return 0; // To prevent division by zero
    }

    // Calculate the total net income for 3 years
    const threeYearNetIncome = annualNetIncome * 3;

    // Calculate the 3-year ROI
    const estimatedROI = (threeYearNetIncome / investmentCost) * 100;

    return estimatedROI.toFixed(2); // Return ROI rounded to 2 decimal places
  }

  return (
    <>
      <GlobalErrorHandler />
      <Sidebar />
      {showWelcomePopup && (
        <WelcomePopup
          open={showWelcomePopup}
          onClose={() => setShowWelcomePopup(false)}
        />
      )}
      <>

        <section className="mt-0 content full properties sm:mt-14">
          {!turnNotificationsOn &&
            !hideNotificationBar &&
            !notificationLoading && (
              <div className="">
                <NotificationTopBar />
              </div>
            )}
          <h1 className="mb-8 font-sans text-3xl font-bold leading-normal tracking-normal text-deep-blue ">
            Marketplace {settings?.blurProperties && <>(coming soon)</>}
          </h1>
          <div className="flex flex-wrap justify-center mb-4 lg:justify-between md:mb-4 lg:mb-4">
            <div className="tabs ">
              <div
                className={getClassNameForTabItem("Available")}
                onClick={() => {
                  handleTabItemClick("Available");
                }}
              >
                Available
              </div>
              {/* <div
                className={getClassNameForTabItem("Coming Soon")}
                onClick={() => {
                  handleTabItemClick("Coming Soon");
                }}
              >
                Coming Soon
              </div> */}
              <div
                className={getClassNameForTabItem("Funded")}
                onClick={() => {
                  handleTabItemClick("Funded");
                }}
              >
                Funded
              </div>
              <div
                className={getClassNameForTabItem("Exited")}
                onClick={() => {
                  handleTabItemClick("Exited");
                }}
              >
                Exited
              </div>
              <div
                className={getClassNameForTabItem("Bookmarked")}
                onClick={() => {
                  handleTabItemClick("Bookmarked");
                }}
              >
                Bookmarked
              </div>
            </div>
            <select
              className="dropdown max-h-14"
              value={selectedType?.id || "all"}
              onChange={handleTypeChange}
            >
              <option value="all">Property type</option>
              {typesData?.propertyTypes.map((type: PropertyType) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
          <div className="gallery">
            {loading &&
              Array.from({ length: 3 }).map((_, index) => (
                <CardSkeleton key={index} />
              ))}
            {((data && data.properties) ||
              (bookmarkedPropertiesData &&
                bookmarkedPropertiesData.bookmarkedProperties)) &&
              properties?.map((property: any, index: number) => (
                <Link
                  to={
                    (property.status === "ComingSoon") ? "" :
                      settings?.blurProperties
                        ? ""
                        : `${AppRoute.PropertyDetails}?id=${property.id}`
                  }
                  className={`item bg-white rounded-lg w-20 shadow-md ${settings?.blurProperties ? "blur" : "non-blur"
                    }`}
                  key={index}
                  onClick={(e) => {
                    if (settings?.blurProperties) {
                      e.preventDefault();
                    }
                  }}
                >
                  {/* <a
                className="w-20 bg-white rounded-lg shadow-md blur item"
                key={index}
              > */}
                  {/* <div className="flex flex-row items-center justify-center gap-2 rounded-t-lg highlight bg-deep-blue -top-left -top-right">
                    <span className="text-yellow-400 lni lni-diamond-alt" />
                    &nbsp;
                    <span className="text-sm font-semibold text-center text-white text">{`New listing`}</span>
                  </div> */}
                  {
                    <div className="relative">
                      {property.images && property.images.length > 0 && (
                        <div className="image-slides">
                          <img
                            className="rounded-t-lg"
                            src={getPropertyImageUrl(
                              property.id,
                              property.images[property.images.length - 1]
                            )}
                            alt="Property"
                          />
                          {property.status === PropertyStatus.Active &&
                            <>
                              <div className="absolute top-0 left-0 px-3 py-1 mt-2 ml-2 bg-yellow-400 border border-yellow-400 rounded-full bg-opacity-40 text-dark-blue">
                                <span className="flex items-center text-sm font-semibold">
                                  <span className="w-2 h-2 mr-1 bg-yellow-400 rounded-full"></span>
                                  {selectedTabItem}
                                </span>
                              </div>
                              <div className="absolute top-0 right-0 px-3 py-1 mt-2 mr-2 border rounded-full bg-slate-200 border-dark-blue text-dark-blue">
                                <span className="flex items-center text-sm font-semibold">
                                  <span className="w-2 h-2 mr-1 rounded-full bg-dark-blue"></span>
                                  Investors:{" "}
                                  {property?.pledges
                                    ? property.pledges.filter(
                                      (pledge: any) =>
                                        pledge.status === PropertyPledgeStatus.Confirmed
                                    ).length
                                    : "0"}
                                </span>
                              </div>
                            </>}
                        </div>
                      )}
                    </div>
                  }
                  <div className="flex flex-col gallery-content">
                    {!(selectedTabItem === "Coming Soon") && <div className="flex flex-row flex-wrap items-center justify-between gap-2 pb-4">
                      <div className="flex items-center gap-2">
                        <div className="flex items-center gap-1 ">
                          <img src={BedSvg} alt="Bed" className="w-5 h-5" />
                          <span className="text-sm text-gray-600">
                            {property.bed}
                          </span>
                        </div>
                        <div className="text-gray-400">|</div>
                        <div className="flex items-center gap-1">
                          <img src={BathSvg} alt="Bath" className="w-5 h-5" />
                          <span className="text-sm text-gray-600">
                            {property.bath}
                          </span>
                        </div>
                        <div className="text-gray-400">|</div>
                        <div className="flex items-center gap-1">
                          <span className="text-sm text-gray-600">
                            {property.sqFootage}sq.ft
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-semibold text-gray-800">
                          Funded
                        </span>
                        <div className="w-24 bg-gray-200 rounded-full h-2.5">
                          <div
                            className="bg-yellow-400 h-2.5 rounded-full"
                            style={{ width: `${fundedPercentage(property)}%` }}
                          ></div>
                        </div>
                        <span className="text-sm font-semibold text-gray-800">
                          {fundedPercentage(property)}%
                        </span>
                      </div>
                    </div>}

                    {property.status === "ComingSoon" && (<div className="text-[#D1B020] pt-4 pb-4 font-['Plus_Jakarta_Sans'] text-[16px] font-bold">Coming Soon</div>)}
                    {(<div className={`${property.status === "ComingSoon" ? "blur-sm pointer-events-none" : ""
                      }`} >

                      <h3 className="font-sans text-lg font-semibold leading-normal tracking-normal title text-deep-blue">
                        {property.title}
                      </h3>


                      <div className="flex gap-4 my-4 ">
                        <div className="px-3 py-1 text-sm font-bold text-black bg-yellow-400 border border-yellow-400 rounded-full bg-opacity-40">
                          <span className="flex items-center text-sm font-bold">
                            <span className="w-2 h-2 mr-1 bg-yellow-400 rounded-full"></span>
                            Price:{" "}
                            {toHumanReadableCurrencyDisplay(property.price)}
                          </span>
                        </div>
                        <div className="px-3 py-1 text-black border rounded-full bg-slate-200 border-dark-blue">
                          <span className="flex items-center text-sm font-bold">
                            <span className="w-2 h-2 mr-1 rounded-full bg-dark-blue"></span>
                            Rental:{" "}
                            {toCurrencyDisplay(
                              property.financials[0].annualRent || 0
                            )}{" "}
                            p.a
                          </span>
                        </div>
                      </div>
                      <div className="my-4 overflow-auto text-sm leading-6 max-h-24" dangerouslySetInnerHTML={{ __html: property?.excerpt || '' }} />


                      <div className="flex flex-col gap-3 pt-4 border-t-2 border-gray-300">
                        <div className="flex justify-between">
                          <span className="text-sm font-bold">
                            3 Years Projected ROI
                          </span>
                          <div className="flex items-center gap-1 ">
                            <img src={ArrowUPSvg} alt="Arrow Up" className="" />
                            <span className="text-sm font-bold text-green-700">
                              {toPercentageDisplay(
                                property.financials[0].year3ProjectedAnnualRoIPercentage
                              )}
                            </span>
                            <span className="text-sm font-thin text-slate-300">|</span>
                            <span className="text-sm font-bold">
                              {toHumanReadableCurrencyDisplay(property.financials[0].year3ProjectedAnnualRoIValue)}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between font-bold">
                          <span className="text-sm font-bold">
                            Projected Annual ROI
                          </span>
                          <div className="flex items-center gap-1 ">
                            <img src={ArrowUPSvg} alt="Arrow Up" className="" />
                            <span className="text-sm font-bold text-green-700">
                              {toPercentageDisplay(property.financials[0].projectedAnnualRoIPercentage)}
                            </span>
                            <span className="text-sm font-thin text-slate-300">|</span>
                            <span className="text-sm font-bold">
                              {toHumanReadableCurrencyDisplay(property.financials[0].projectedAnnualRoIValue)}
                            </span>
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <span className="text-sm font-bold">
                            Income per Annum
                          </span>
                          <div className="flex items-center gap-1 ">
                            <span className="text-sm font-bold">
                              {" "}
                              {toHumanReadableCurrencyDisplay(
                                property.financials[0].annualRent
                              ) || 0}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>)}

                    {/* <div className="flex items-baseline justify-between pricing">
                      <h4 className="text-lg font-semibold text-yellow-500">
                        {toCurrencyDisplay(property.price)}
                      </h4>
                      <h5 className="text-sm font-light">
                        {selectedTabItem === "Funded" ? 100 : 0}% funded
                      </h5>
                    </div>
                    <div className="flex items-baseline justify-between mb-2 ">
                      <h4 className="text-sm font-semibold">
                        Est. 1y ROI {calculateEstimatedROI(property)}%
                      </h4>
                      <h5 className="text-sm font-light">
                        {property?.pledges.length || 0} Investors
                      </h5>
                    </div> */}
                    {/* <div className="progress">
                      <div className="baseline"></div>
                      {selectedTabItem === "Funded" && (
                        <div
                          className="current"
                          style={{
                            maxWidth: `${
                              selectedTabItem === "Funded" ? 100 : 0
                            }%`,
                          }}
                        ></div>
                      )}
                    </div>
                    <table className="bg-gray-100 rounded-md financials">
                      <tbody>
                        {property.financials &&
                          property.financials.length > 0 && (
                            <>
                              <tr>
                                <td className="py-1 pl-2">
                                  Annual rate of return
                                </td>
                                <td className="pr-1 text-base font-semibold text-right">
                                  {toPercentageDisplay(
                                    property.financials[0].annualizedReturn
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="py-1 pl-2">
                                  Annual appreciation
                                </td>
                                <td className="pr-1 text-base font-semibold text-right">
                                  {toPercentageDisplay(
                                    property.financials[0].annualAppreciation
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="py-1 pl-2">
                                  Project gross yield
                                </td>
                                <td className="pr-1 text-base font-semibold text-right">
                                  {toPercentageDisplay(
                                    property.financials[0].projectedGrossYield
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="py-1 pl-2">Project net yield</td>
                                <td className="pr-1 text-base font-semibold text-right">
                                  {toPercentageDisplay(
                                    property.financials[0].projectedNetYield
                                  )}
                                </td>
                              </tr>
                            </>
                          )}
                      </tbody>
                    </table> */}
                  </div>
                  {/* </a> */}
                </Link>
              ))}
            {((data &&
              data.properties &&
              data.properties.length === 0 &&
              selectedTabItem !== "Bookmarked") ||
              (bookmarkedPropertiesData &&
                bookmarkedPropertiesData.bookmarkedProperties &&
                bookmarkedPropertiesData.bookmarkedProperties.length === 0 &&
                selectedTabItem === "Bookmarked")) && (
                <div className="mt-4 text-center no-properties">
                  No {selectedTabItem} properties
                </div>
              )}
          </div>
        </section>
      </>
    </>
  );
}
