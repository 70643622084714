import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SvgLogo } from '../../../assets/img/common/logo.svg';
import { AppRoute } from '../../../routes';
import { GlobalErrorHandler } from '../../../components/error/global_error.component';
import { MUTATION_SEND_OTP, MUTATION_VERIFY_OTP } from '../../../graphql/mutations/user';
import { QUERY_CHECK_VERIFICATION_OPTIONS } from '../../../graphql/queries/get-otp-verification';
import { AuthService } from '../../../services/auth.service';

export function OtpPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verifyOtp, { loading, error }] = useMutation(MUTATION_VERIFY_OTP, { errorPolicy: 'all' });
  const [sendOtp, { loading: sendOtpLoading, error: sendOtpError }] = useMutation(MUTATION_SEND_OTP, { errorPolicy: 'all' });
  const [deviceId, setDeviceId] = useState<string>('');
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [otpMethod, setOtpMethod] = useState<'phone' | 'email' | null>(null);
  const [userId, setUserId] = useState<string>('');

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  async function resendLink() {
    setInfoMessage('Resending OTP. Please wait...');
    setErrorMessage('');
    await sendOtp({ variables: { userId, deviceId, method: otpMethod } });
    setInfoMessage('OTP sent successfully');
    setErrorMessage('');
  }

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('token')?.trim();
    const deviceId = localStorage.getItem('deviceId')?.trim();
    if (isAuthenticated && deviceId) {
      navigate(AppRoute.Home);
    }
  }, []);

  useEffect(() => {
    const deviceIdToSet = id || localStorage.getItem('deviceId') || '';
    const userIdToSet = localStorage.getItem('userId') || '';

    if (!deviceIdToSet || !userIdToSet) {
      navigate(AppRoute.AccountLogin);
    } else {
      setDeviceId(deviceIdToSet);
      setUserId(userIdToSet);
    }
  }, [location.search, navigate]);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    setInfoMessage('Verifying OTP. Please wait...');
    setErrorMessage('');

    const { data } = await verifyOtp({ variables: { userId, deviceId, otp: verificationCode, method: otpMethod } });
    if (data) {
      AuthService.login(data.verifyOTP.user.email, data.verifyOTP.token, data.verifyOTP.lastLogin, deviceId);
      setInfoMessage('OTP verified successfully');
      setErrorMessage('');
      setOtpSent(false);
      navigate(AppRoute.Home);
    } else {
      setErrorMessage('OTP verification failed');
      setInfoMessage('');
    }
  }

  async function handleOtpMethod(method: 'phone' | 'email') {
    setOtpMethod(method);
    setInfoMessage(`Sending OTP to your ${method}. Please wait...`);
    setErrorMessage('');

    try {
      const response = await sendOtp({ variables: { userId, deviceId, method } });
      if (response.errors) {
        setErrorMessage('Failed to send OTP. Please try again.');
      } else {
        setInfoMessage(`OTP sent to your ${method}. Please check your inbox or messages.`);
        setOtpSent(true);
      }
    } catch (error: any) {
      setErrorMessage(error.message || 'An unexpected error occurred. Please try again.');
    }
  }

  return (
    <section className="flex flex-col content account">
      <GlobalErrorHandler />
      <SvgLogo className="mx-auto text-center max-w-52" />
      <h1 className="mt-16 mb-4 font-sans text-5xl font-bold leading-normal tracking-wide text-deep-blue">Verify Your Account</h1>
      {!otpSent ? (
        <div className="flex flex-col items-center w-1/2 max-w-md px-8 pt-4 pb-12 mx-auto bg-white rounded-lg shadow-md card">
          <p className="mt-8 mb-12 text-center">
            To enhance the security of your account, please choose how you'd like to receive the One-Time Password (OTP).
          </p>
          <div className="flex flex-col gap-4">
            <button
              className={`py-6 text-xl btn btn-primary hover:bg-deep-blue`}
              onClick={() => handleOtpMethod('email')}
            >
              Verify Email
            </button>
            <button
              className={`py-6 text-xl btn btn-primary hover:bg-deep-blue`}
              onClick={() => handleOtpMethod('phone')}
            >
              Verify Phone
            </button>
          </div>
          {errorMessage && <div className="px-12 py-6 mt-4 text-red-500 border rounded-lg bg-light-grey -600">{errorMessage}</div>}
        </div>
      ) : (
        <form className="w-1/2 max-w-md p-8 mx-auto bg-white rounded-lg shadow-md card" onSubmit={onSubmit}>
          <p className="mb-8 text-center">We've sent a One-Time Password (OTP) to your {otpMethod}. Please enter it below.</p>
          <div className="flex flex-col gap-2 p-4">
            <label className="mb-2 -mt-2 text-base font-bold tracking-normal">Verification code</label>
            <input
              className="box-border w-full p-4 text-base border border-gray-300 rounded"
              autoFocus
              type="text"
              value={verificationCode}
              placeholder="Enter your verification code"
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </div>

          {infoMessage && <div className="px-12 py-6 mb-12 border rounded-lg bg-light-grey">{infoMessage}</div>}
          {errorMessage && <div className="px-12 py-6 mb-12 text-red-500 border rounded-lg bg-light-grey -600">{errorMessage}</div>}

          <div className="flex flex-wrap justify-center gap-2 p-4">
            <button type="submit" className="btn btn-primary" style={{ height: '3rem' }}>
              Verify
            </button>
            <button className="btn btn-secondary" type="button" onClick={resendLink} style={{ height: '3rem' }}>
              Resend link
            </button>
          </div>
        </form>
      )}
    </section>
  );
}
